import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import SeoComp from "../../components/SeoComp"
import HotelsTopSection from "../../components/HotelsTopSection"
import HotelsServiceSection from "../../components/HotelsServiceSection"
import HotelsProductSection from "../../components/HotelsProductSection"
import HotelsProfessionalSection from "../../components/HotelsProfessionalSection"
import SoulagerSection from "../../components/Products/SoulagerSection"
import ProductSainSection from "../../components/Products/ProductSainSection"
import ProductFaqSection from "../../components/Products/ProductFaqSection"

const matelas = ({ intl, data }) => {
  useEffect(() => {
    if (intl.locale != "fr") navigate(`/${intl.locale}/`)
  }, [intl.locale])

  return (
    process.env &&
    (process.env.GATSBY_ACTIVE_ENV === "eur" ||
      process.env.GATSBY_ACTIVE_ENV === "general") &&
    intl.locale === "fr" &&
    data && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "matelas-hotels-professionnels",
            en: "",
            de: "",
            es: "",
          }}
        />

        {data.allDirectusMatelasHotelsTranslation.nodes[0].top_section && (
          <HotelsTopSection
            data={data.allDirectusMatelasHotelsTranslation.nodes[0].top_section}
            formData={
              data.allDirectusMatelasHotelsTranslation.nodes[0].top_form_error
            }
            id="hotels_top_section"
          />
        )}

        {data.allDirectusMatelasHotelsTranslation.nodes[0].service_section && (
          <HotelsServiceSection
            data={
              data.allDirectusMatelasHotelsTranslation.nodes[0].service_section
            }
          />
        )}

        {data.allDirectusMatelasHotelsTranslation.nodes[0].product_section && (
          <HotelsProductSection
            data={
              data.allDirectusMatelasHotelsTranslation.nodes[0].product_section
            }
          />
        )}

        {data.allDirectusMatelasHotelsTranslation.nodes[0]
          .professional_section && (
          <HotelsProfessionalSection
            data={
              data.allDirectusMatelasHotelsTranslation.nodes[0]
                .professional_section
            }
          />
        )}

        {data.allDirectusMatelasHotelsTranslation.nodes[0]
          .laboratory_section && (
          <SoulagerSection
            data={
              data.allDirectusMatelasHotelsTranslation.nodes[0]
                .laboratory_section
            }
            page="matelas_hotels"
          />
        )}

        {data.allDirectusMatelasHotelsTranslation.nodes[0]
          .product_sain_section && (
          <ProductSainSection
            data={
              data.allDirectusMatelasHotelsTranslation.nodes[0]
                .product_sain_section
            }
            page="matelas_hotels"
          />
        )}

        {data.allDirectusMatelasHotelsTranslation.nodes[0]
          .product_faq_section && (
          <ProductFaqSection
            data={
              data.allDirectusMatelasHotelsTranslation.nodes[0]
                .product_faq_section
            }
            pageName="matelas"
          />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusMatelasHotelsTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        top_section {
          left_section {
            left_logo
            left_logo_alt
            title
            subtitle
            button_text
            bottom_text
          }
          right_section {
            right_logo
            right_logo_alt
            right_image
            right_image_alt
          }
        }
        top_form_error {
          require_error_text
          field_error_text
          email_error_text
          numeric_error_text
          email_sent_success
        }
        service_section {
          section_title
          mobile_section_title
          button_text
          list {
            icon_logo
            icon_logo_alt
            title
            text
          }
        }
        product_section {
          section_title
          list {
            image
            image_alt_tag
            title
            subtitle
            text
            label_text
          }
        }
        professional_section {
          section_title
          section_text
          button_text
          image
          image_alt
        }
        technology_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          button_text
          list {
            image
            alt
            title
            subtitle
            icon_image
            author_name
            designation
            text
          }
        }
        laboratory_section {
          section_title
          image
          alt
          title
          subtitle
          button_text
        }
        product_sain_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          bottom_text
          mobile_bottom_text
          list {
            image
            alt
            top_image
            title
            text
            mobile_text
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              alt
              text
            }
            open
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "matelas_hotel" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(matelas)
